<template>
  <div>
    <div class="navigation">
      <img src="../../assets/mobileImgs/logo.png" alt style=" width: 2.13rem;height: .49rem;" />
      <img
        src="../../assets/mobileImgs/bar.png"
        alt
        style="width: .47rem;height: .37rem;"
        @click="handleBar"
      />
      <div class="phone" @click="dial" id="callButton">
        <img src="../../assets/mobileImgs/phone.png" alt />
        <span>400-1231-0852</span>
      </div>
    </div>
    <!-- 导航栏 -->
    <el-drawer
      title="侧边栏"
      :visible.sync="drawer"
      :with-header="false"
      direction="ltr"
      size="4.14rem"
    >
      <div class="sidebar">
        <div class="head">
          <img src="../../assets/images/login/qq.png" alt />
          <span @click="getLogin">立即登录</span>
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @select="handleSelect">
          <el-menu-item index="/home-page">
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="/product">
            <template slot="title">
              <span>产品介绍</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/systemConstruction">体系搭建</el-menu-item>
              <el-menu-item index="/systemConstruction">体系维护</el-menu-item>
              <el-menu-item index="/systemConstruction">体系落地</el-menu-item>
              <el-menu-item index="/systemConstruction">管理软件</el-menu-item>
              <el-menu-item index="/systemConstruction">培训课程</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="3">
            <span slot="title">产品优势</span>
          </el-menu-item>
          <el-menu-item index="/solution">
            <span slot="title">解决方案</span>
          </el-menu-item>
          <el-menu-item index="5">
            <span slot="title">合作客户</span>
          </el-menu-item>
          <el-menu-item index="/aboutUs">
            <span slot="title">关于我们</span>
          </el-menu-item>
          <el-menu-item index="/que-bank-system">
            <span slot="title">题库体系</span>
          </el-menu-item>
          <el-menu-item index="7">
            <span slot="title">退出登录</span>
          </el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      activeIndex: "",
    };
  },
  methods: {
    getLogin() {
      window.location.href = "http://101.33.249.65:8012/";
      // window.open("http://101.33.249.65:8012/","_blank");
    },
    handleBar() {
      this.drawer = true;
    },
    handleSelect(key) {
      this.activeIndex = key;
      this.$router.push({ path: key });
    },
    dial() {
      document
        .getElementById("callButton")
        .addEventListener("click", function () {
          // 使用window.location.href来模拟点击<a>标签的行为
          window.location.href = "tel:40012310852";
        });
    },
  },
};
</script>

<style lang="less" scoped>
.navigation {
  width: 100%;
  height: 1.26rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: url("../../assets/mobileImgs/banner.png") no-repeat;
  background-size: cover;
  .phone {
    img {
      width: 0.33rem;
      height: 0.39rem;
      vertical-align: middle;
    }
    span {
      font-size: 0.3rem;
      font-weight: bold;
      color: #ffffff;
      vertical-align: middle;
      margin-left: 0.12rem;
    }
  }
}
.sidebar {
  .head {
    width: 100%;
    height: 1.35rem;
    border-bottom: 1px dashed #c0c0c0;
    padding: 0 0.54rem 0 0.26rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    img {
      width: 0.54rem;
      height: 0.54rem;
      border-radius: 50%;
    }
    span {
      font-size: 0.28rem;
      font-weight: 500;
      color: #1058c8;
    }
  }
}
</style>