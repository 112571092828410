<template>
  <div class="container">
    <Header></Header>
    <div class="banner"></div>
    <div class="synopsis">
      <div class="synopsis-box">
        <div>Company Profile</div>
        <div>公司简介</div>
        <div>—— 广州市景翔信息科技有限公司</div>
      </div>
      <p>
        公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司
        公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍公司介绍
      </p>
      <div class="contact">
        <div>联系我们</div>
        <div class="line"></div>
        <div>在线留言，我们将会在24小时内回复您的信息</div>
        <div></div>
      </div>
    </div>
    <div class="form">
      <div class="leave">
        <span></span>
        <span>在线留言</span>
      </div>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input v-model="form.name" placeholder="姓名（必填）"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.phone" placeholder="联系电话（必填）"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.companyName" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.industry" placeholder="公司行业"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="textarea" v-model="form.suggest" :rows="4" placeholder="在此输入您的问题或者建议"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="formBtn">立即提交</el-button>
        </el-form-item>
        <div class="service">
            <div>服务热线</div>
            <div>注册咨询/软件使用咨询等问题处理</div>
            <div>+86 15316241867</div>
            <div>立即拨打</div>
        </div>
      </el-form>
      <div class="leave">
        <span></span>
        <span>联系方式</span>
      </div>
      <div class="relation">
        <div>广州市景翔信息科技有限公司</div>
        <div>公司座机：200-3835-3517</div>
        <div>公司电话：200-3835-3517</div>
        <div>公司邮箱：200-3835-3517</div>
        <div>公司地址：200-3835-3517 <img src="../../assets/mobileImgs/site.png" alt=""></div>
      </div>
      <div class="code">
        <img src="../../assets/mobileImgs/code.jpg" alt="">
        <div>关注微信公众号</div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/mobile/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      form: {},
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  background-color: #ffffff;
  .banner {
    width: 100%;
    height: 4.89rem;
    background: url("../../assets/mobileImgs/banner2.png") no-repeat;
    background-size: 100% 100%;
  }
  .synopsis {
    padding: 0.54rem 0.2rem 0;
    box-sizing: border-box;
    .synopsis-box {
      text-align: right;
      font-weight: bold;
      & > div:nth-child(1) {
        height: 0.4rem;
        font-size: 0.4rem;
        color: #000000;
        line-height: 0.4rem;
      }
      & > div:nth-child(2) {
        height: 0.2rem;
        font-size: 0.2rem;
        color: #24569c;
        line-height: 0.2rem;
        margin: 0.14rem 0 0.42rem;
      }
      & > div:nth-child(3) {
        height: 0.28rem;
        font-size: 0.28rem;
        color: #454545;
        line-height: 0.28rem;
      }
    }
    p {
      text-indent: 2em;
      font-size: 0.28rem;
      font-weight: 500;
      color: #555555;
      line-height: 0.48rem;
      margin-top: 0.34rem;
    }
    .contact {
      text-align: center;
      margin-top: 0.5rem;
      & > div:nth-child(1) {
        height: 0.32rem;
        font-size: 0.32rem;
        font-weight: bold;
        color: #434343;
        line-height: 0.32rem;
      }
      .line {
        width: 0.43rem;
        height: 0.04rem;
        background: #000000;
        margin: 0.2rem auto;
      }
      & > div:nth-child(3) {
        height: 0.26rem;
        font-size: 0.26rem;
        font-weight: 400;
        color: #434343;
        line-height: 0.26rem;
        margin-top: 0.32rem;
      }
      & > div:nth-child(4) {
        width: 100%;
        height: 0.02rem;
        background: #d4d4d4;
        box-shadow: 0px 0.02rem 0.05rem 0.02rem rgba(210, 210, 210, 0.81);
        margin-top: 0.22rem;
      }
    }
  }
  .form {
    padding: 0.6rem 0.5rem 0;
    box-sizing: border-box;
    .leave {
      margin-bottom: 0.14rem;
      & > span:nth-child(1) {
        display: inline-block;
        width: 0.04rem;
        height: 0.48rem;
        background: #023489;
        vertical-align: middle;
      }
      & > span:nth-child(2) {
        font-size: 0.3rem;
        font-weight: bold;
        color: #204495;
        margin-left: 0.22rem;
        vertical-align: middle;
      }
    }
    .el-form-item {
      margin-bottom: 0.22rem;
      ::v-deep .el-input__inner {
        height: 0.78rem !important;
        line-height: 0.78rem !important;
        border-radius: 0 !important;
        font-size: 0.28rem !important;
      }
      .formBtn{
        width: 100%;
        height: 0.78rem !important;
      }
    }
    .service{
        height: 3.5rem;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        box-shadow: 0px 0.03rem 0.08rem 0px rgba(169,170,172,0.18);
        margin-top: .35rem;
        text-align: center;
        font-weight: 500;
        margin-bottom: .35rem;
        &>div:nth-child(1){
            height: 0.26rem;
            font-size: 0.28rem;
            color: #4E90FF;
            line-height: 0.26rem;
            margin-top: .44rem;
        }
        &>div:nth-child(2){
            height: 0.28rem;
            font-size: 0.28rem;
            color: #333333;
            line-height: 0.28rem;
            margin-top: .55rem;
        }
        &>div:nth-child(3){
            height: 0.22rem;
            font-size: 0.28rem;
            color: #333333;
            line-height: 0.22rem;
            margin-top: .32rem;
        }
        &>div:nth-child(4){
            width: 4.74rem;
            height: 0.78rem;
            background: #4E90FF;
            border-radius: 0.39rem;
            font-size: 0.28rem;
            color: #FFFFFF;
            line-height: 0.78rem;
            margin: 0 auto;
            margin-top: .36rem;
        }
    }
    .relation{
        padding-left: .22rem;
        box-sizing: border-box;
        div:first-child{
            height: .3rem;
            font-size: .3rem;
            font-weight: bold;
            color: #000000;
            line-height: .3rem;
            margin-bottom: .22rem;
        }
        div{
            height: 0.28rem;
            font-size: 0.28rem;
            font-weight: 500;
            color: #9C9C9C;
            line-height: 0.28rem;
            margin-bottom: .16rem;
            img{
                width: .25rem;
                height: .31rem;
                margin-left: .26rem;
            }
        }
    }
    .code{
        width: 100%;
        text-align: center;
        padding: 0 0 .52rem 0;
        img{
            width: 3.27rem;
            height: 3.27rem;
        }
        div{
            height: 0.28rem;
            font-size: 0.28rem;
            font-weight: 400;
            color: #787878;
            line-height: 0.28rem;
        }
    }
  }
}
</style>